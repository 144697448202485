<!-- 模板 -->
<template>
  <div class="">
    <div class="contant">
      <headers class="top" :active="3"></headers>
      <img class="imhg" :src="$baseUrl + ban_img" alt="" />
      <div class="people">
        <div class="wu block_p">
          <div class="zu">
            <div class="line"></div>
            <div class="tit">{{ arr.name }}</div>
            <div class="line"></div>
          </div>
          <div class="chi">Black enterprises</div>
          <div class="our">
            <div style="width: 60%">
              <el-autocomplete
                class="inline-input"
                v-model="state2"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
              <el-button type="primary" @click="submit" class="submit"
                >搜索</el-button
              >
            </div>
            <div class="ouus" @click="dialogVisible = true">我要曝光</div>
          </div>
          <!-- 列表 -->
          <div class="ops">
            <div
              class="ops_list"
              v-for="(it, i) in culture.content"
              :key="i"
              @click="btn(it)"
            >
              <div class="list_l">
                <img :src="$baseUrl + it.image" alt="" />
                <div class="agd">
                  <div class="a">{{ it.title }}</div>
                  <div class="b">发布者：{{ it.username }}</div>
                  <div class="c" v-html="it.desc"></div>
                </div>
              </div>
              <div class="list_r" v-if="it.publishtime != null">
                <div class="today">{{ it.publishtime.substr(8, 2) }}</div>
                <div class="month">{{ it.publishtime.slice(0, 10) }}</div>
              </div>
            </div>
          </div>
          <div class="pagesall">
            <el-pagination
              @current-change="next"
              background
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <img src="../assets/image/5.png" alt="" class="img_s" />
        <img class="img_k" src="../assets/image/18.png" alt="" />
      </div>
      <el-dialog
        title="发布曝光"
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose"
        center
      >
        <div class="input_tit">
          <div class="nei">标题</div>
          <el-input
            class="elinput"
            v-model="input"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div class="input_tit">
          <div class="nei">发布人</div>
          <el-input
            class="elinput"
            v-model="input2"
            placeholder="请输入内容"
          ></el-input>
        </div>

        <div class="input_tit">
          <div class="nei">描述</div>
          <el-input
            class="elinput"
            v-model="desc"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div class="input_tit">
          <div class="nei">上传封面</div>
          <el-upload
            class="upload-demo"
            action="http://zudao.wuyi.link/api/common/upload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="success"
            :file-list="fileList"
            :limit="1"
            list-type="picture"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </div>
        <div class="input_tits">
          <div class="nei">发布内容</div>
          <vue-editor
            class="editor"
            v-model="content"
            useCustomImageHandler
            @image-added="handleImageAdded"
          ></vue-editor>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="tijiao">确 定</el-button>
        </span>
      </el-dialog>

      <footers></footers>
      <!-- <sides></sides> -->
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import request from "../utils/request.js";
import headers from "../assembly/head.vue"; //头部
import footers from "@/assembly/footer"; //页脚
import axios from "axios";
import qs from "qs";
// import sides from "@/assembly/side"; //侧边
export default {
  name: "",
  data() {
    return {
      arr: [],
      who: {},
      culture: {},
      producrs: {},
      restaurants: [],
      state1: "",
      state2: "",
      dialogVisible: false,
      content: "<h1>Some initial content</h1>",
      input: "",
      page: 1,
      page_size: 10,
      total: 0,
      ban_img: "",
      input2: "",
      editorContent: "",
      customToolbar: [],
      isdisable: true,
      desc: "",
      fileList: [],
      img: "",
    };
  },
  props: [],

  components: {
    headers,
    VueEditor,
    footers,

    // sides,
  },
  computed: {},
  filters: {},
  methods: {
    success(file) {
      console.log(file);
      this.img = file.data.url;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    async tijiao() {
      var a = {
        cid: 26,
        title: this.input,
        desc: this.desc,
        content: this.content,
        image: this.img,
        username: this.input2,
      };
      axios({
        method: "post",
        url: "http://zudao.wuyi.link/api/index/addcontent",
        data: qs.stringify(a),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          // "Content-Type": "multipart/form-data;charset=utf-8",
        },
      }).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          this.input = "";
          this.input2 = "";
          this.content = "";
          this.img = "";
          this.desc = "";
          this.dialogVisible = false;
        } else {
          this.$message.error("请求数据失败");
        }
      });

      // let options = {
      //   url: "/index/addcontent",
      //   type: "post",
      //   data: {
      //     cid: "",
      //     title: this.input,
      //     content: this.content,
      //     username: this.input2,
      //   },
      // };
      // try {
      //   let res = await request(options);
      //   if (res.data.code !== 1) {
      //     return this.$message.error("请求数据失败");
      //   }
      //   this.input = "";
      //   this.input2 = "";
      //   this.content = "";
      //   this.dialogVisible = false;
      // } catch (error) {
      //   this.$message.error("请求数据失败");
      // }
    },
    //富文本编辑器图片上传回调
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      console.log(file);
      console.log(Editor, cursorLocation, resetUploader);
      var formData = new FormData();
      formData.append("file", file);
      console.log(formData.get("file"));
      axios({
        method: "post",
        url: "http://zudao.wuyi.link/api/common/upload",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        console.log(res);
        Editor.insertEmbed(cursorLocation, "image", res.data.data.url);
        resetUploader();
      });
      // let options = {
      //   url: "/common/upload",
      //   type: "post",
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      //   data: {
      //     file: formData.get('file'),
      //   },
      // };
      // try {
      //   let res = await request(options);
      //   if (res.data.code !== 1) {
      //     return this.$message.error("请求数据失败");
      //   }
      //   Editor.insertEmbed(cursorLocation, "image", res.data.url);
      //   resetUploader();
      // } catch (error) {
      //   this.$message.error("请求数据失败");
      // }
      // const res = await uploads(formData)
      //   console.log(res);
    },
    async upload(e) {
      let options = {
        url: "/common/upload",
        type: "post",
        data: {
          file: e,
        },
      };
      try {
        let res = await request(options);
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
      } catch (error) {
        this.$message.error("请求数据失败");
      }
    },
    next() {
      this.List();
    },
    submit() {
      this.page = 1;
      this.List();
    },
    handleClose(done) {
      done();
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    btn(e) {
      console.log(e);
      this.$router.push({ path: "/qualifications", query: { id: e.id } });
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return [
        { value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号" },
        {
          value: "Hot honey 首尔炸鸡（仙霞路）",
          address: "上海市长宁区淞虹路661号",
        },
        {
          value: "新旺角茶餐厅",
          address: "上海市普陀区真北路988号创邑金沙谷6号楼113",
        },
        { value: "泷千家(天山西路店)", address: "天山西路438号" },
      ];
    },
    handleSelect(item) {
      console.log(item);
    },
    async swper() {
      let options = {
        url: "/index/getContentByChannel",
        type: "post",
        data: {
          channel_id: 24,
        },
      };
      try {
        let res = await request(options);
        console.log(res);
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        this.ban_img = res.data.data.content[0].images[0];
      } catch (error) {
        console.log(error);
        this.$message.error("请求数据失败");
      }
    },
    async List() {
      let options = {
        url: "/index/getContentByChannel",
        type: "post",
        data: {
          channel_id: 4,
          search: this.state2,
          page: this.page,
          page_size: this.page_size,
        },
      };
      try {
        let res = await request(options);
        console.log(res);
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        this.arr = res.data.data;
        this.who = res.data.data.child[0];
        this.culture = res.data.data.child[2];
        this.total = res.data.data.child[1].total;
        console.log(this.total);
        console.log(this.culture);
      } catch (error) {
        console.log(error);
        this.$message.error("请求数据失败");
      }
    },
    async product() {
      let options = {
        url: "/index/getContentByChannel",
        type: "post",
        data: {
          channel_id: 12,
        },
      };
      try {
        let res = await request(options);
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        // console.log(res.data.data);
        this.producrs = res.data.data;
      } catch (error) {
        console.log(error);
        this.$message.error("请求数据失败");
      }
    },
  },
  created() {
    this.List();
    this.swper();
    // this.product();
  },
  mounted() {
    this.restaurants = this.loadAll();
    let _this = this;
    // 页面渲染结束再执行动画
    this.$nextTick(function () {
      new _this.WOW({ live: false }).init();
    });
  },
};
</script>

<style lang="less" scoped>
//@import url(); 引入公共css类
.submit {
  margin-left: 15px;
}
.inline-input {
  width: 70%;
}
.input_tit {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  .nei {
    width: 100px;
  }
  .elinput {
    width: 80%;
  }
}
/deep/.ql-container {
  height: 80% !important;
}
.input_tits {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  .nei {
    width: 100px;
  }
  .editor {
    width: 80%;
  }
}
.contant {
  position: relative;

  .top {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  .imhg {
    width: 100%;
    display: block;
  }
  .people {
    position: relative;
    overflow: hidden;
    .img_s {
      width: 360px;
      position: absolute;
      right: -190px;
      top: 0;
      // margin-right: -190px;
      margin-top: 1200px;
    }
    .img_k {
      width: 360px;
      position: absolute;
      top: 0;
      margin-top: 2100px;
    }
    .block_p {
      padding-top: 60px;
      .zu {
        display: flex;
        align-items: center;
        justify-content: center;

        .line {
          width: 120px;
          height: 2px;
          background: #776360;
          opacity: 1;
        }
        .tit {
          font-size: 40px;
          font-weight: bold;
          line-height: 68px;
          color: #776360;
          letter-spacing: 50px;
          opacity: 1;
          margin: 0 30px;
          padding-left: 40px;
        }
      }
      .chi {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        color: #776360;
      }
      .our {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 60px;
        margin-bottom: 20px;
        .ouus {
          text-align: center;
          width: 120px;
          height: 40px;
          line-height: 40px;
          background: rgba(119, 99, 96);
          opacity: 1;
          border-radius: 20px;
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .ops {
        position: relative;
        border-bottom: 1px solid #f3f1f2;
        overflow: hidden;
        .ops_list {
          padding: 40px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          border-bottom: 1px solid #ececec;
          &:hover {
            background: #ddd;
          }
          .list_l {
            display: flex;
            img {
              width: 267px;
              margin-right: 20px;
            }
            .agd {
              .a {
                font-size: 24px;
                font-weight: bold;
                line-height: 41px;
                color: #252121;
              }
              .b {
                font-size: 20px;
                font-weight: 400;
                line-height: 34px;
                color: #252121;
              }
              .c {
                font-size: 20px;
                font-weight: 400;
                line-height: 28px;
                color: #252121;
                width: 662px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-top: 30px;
              }
            }
          }
          .list_r {
            width: 127px;
            padding: 20px 0;
            background: rgba(255, 255, 255, 0.39);
            border: 2px solid #776360;
            opacity: 1;
            text-align: center;
            .today {
              font-size: 40px;
              font-weight: bold;
              line-height: 28px;
              color: #776360;
            }
            .month {
              margin-top: 20px;
              font-size: 20px;
              font-weight: 400;
              line-height: 28px;
              color: #776360;
              opacity: 1;
            }
          }
        }
      }
      .pagesall {
        margin-top: 20px;
        text-align: right;
        margin-bottom: 57px;
        /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #776360;
          color: #fff;
        }
      }
    }
  }
}
</style>
<style>
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
